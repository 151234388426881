@import "../../sass/_variables.scss";
@import "../../sass/_mixin.scss";

.track {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  box-shadow: 5px 6px 30px 0px rgba(0, 0, 0, 0.1);
  padding: 2rem 1rem;
  position: relative;
  top: -5rem;
  width: 50%;
  min-width: 400px;
  margin: auto;
  z-index: 10;
  background-color: white;

  &__heading {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
    text-align: center;
  }

  @media screen and (max-width: 868px) {
    width: 100%;
    min-width: 0;
  }

  // border: 5px solid $blue;
}

.track__order {
  input {
    height: 50px;
    border: 1px solid $orange;
    padding: 0 20px;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  button {
    height: 50px;
    border: 1px solid $orange;
    padding: 0 18px;
    font-size: 1rem;
    background-color: $orange;
    color: $white-bg;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background-color: $blue;
      color: white;
    }
  }

  @media only screen and (max-width: 868px) {
    input {
      width: 100%;
    }

    button {
      width: 100%;
      margin: auto;
      text-align: center;
    }
  }
}